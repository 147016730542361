import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Input, Button, Pagination, Dropdown, Select, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputComp } from "../../common/component";

import { CommissionManageService } from '../commission/commissionService';
import { CurrentUserService } from "../user/currentUserService";

import PostCommission from "./postCommission";

const { RangePicker } = DatePicker;

const ManageCommission = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });

  const [compState, setCompState] = useState({
    defaultFormDate: moment().startOf('month'),
    defaultToDate: moment(),
    itemList:[]
  });
  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });
  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    orderBy: 'DateDec',
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().format("YYYY-MM-DD"),
    partnerId: null
  })

  const partnerListRef = useRef([]);

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCurrentUserState({currentUser: user});
		});

    init();

		return () => {
			userCurrentSubscription.unsubscribe();
		}
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  const init = () => {
    Popup.spin.show();
    CommissionManageService.init().then(res => {
      Popup.spin.hide()
      if(res.success){
        partnerListRef.current = res.partnerList
      }
    })
  }

  const getList = () => {
    Popup.spin.show();
    CommissionManageService.getList({ ...filterState }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          itemList: res.itemList,
          totalIncomeValue: res.totalIncomeValue,
          totalCommissionValue: res.totalCommissionValue,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onChangeRangepicker = (dates) => {
    if (dates.length > 1) {
      setFilterState({
        ...filterState,
        fromDate: dates[0].format("YYYY-MM-DD"),
        toDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  }

  const onChangePartner = (value) => {
    setFilterState({
      ...filterState,
      partnerId: value
    });
  }

  const onChangeSearchCommission = (event) => {
    setFilterState({
      ...filterState,
      searchKey: event.target.value 
    })
  }

  const onFunctionUpdate = (e) => {
		e.preventDefault();

		Popup.info({
			title: "Tính năng đang tạm khoá",
		});
	};

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      isItemDrawer: false,
    });
  };

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  }

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Hoa hồng</h1>
      <Row align="middle" justify="end">
        <Col>
          <RangePicker
            defaultValue={[compState.defaultFormDate, compState.defaultToDate]}
            allowEmpty={[false, false]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRangepicker}
            allowClear={false}
          />
        </Col>
        {partnerListRef.current &&
          <Col>
            <Select
              value={compState.partnerId}
              showSearch
              allowClear
              style={{ width: "160px" }}
              placeholder="Tìm đối tác"
              optionFilterProp="children"
              onChange={onChangePartner}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
            {partnerListRef.current.map(item => (
              <Select.Option key={item.partnerId} value={item.partnerId}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          </Col>
        }
        <Col>
          <InputComp placeholder="Tìm số xe hoặc SĐT" onChange={onChangeSearchCommission} value={filterState.searchKey}/>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        <div className="title-box">
          <h3>Doanh thu: <strong className="income">{Format.number(compState.totalIncomeValue)}</strong></h3>
          <h3>Hoa hồng: <strong className="commission">{Format.number(compState.totalCommissionValue)}</strong></h3>
        </div>
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Số xe</li>
            <li>Đối tác</li>
            <li>Ghi chú</li>
            <li>Thanh toán</li>
            <li>Hoa hồng</li>
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <div><strong onClick={() => this.onEditItem(item)}>{item.name}</strong></div>
                {item.phone && item.phone > 0 && <div><em>{item.phone}</em></div>}
                <a onClick={() => this.onEditItem(item)}>{item.extId}</a>
                <div>
                  <em>Ngày thuê:</em> {Format.dateTime(new Date(item.dateCreated))}
                </div>
                <div>
                  <em>Ngày trả:</em> {item.dateReturn ? Format.dateTime(new Date(item.dateReturn)) : ""}
                </div>
              </li>
              <li>{item.partner}</li>
              <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}></li>
              <li>
                <div>{Format.number(item.payment)}</div>
                <div><em>{Format.number(item.price)}</em></div>
              </li>
              <li>
                {item.commission > 0 && Format.number(item.commission)}
                {item.isFinishedCommission && <i className="icon fas fa-check-circle" style={{color:'green', paddingLeft:'5px'}}></i>}
              </li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li style={{float:'right'}}>
                <Button type="primary" onClick={() => onEditItem(item)}>
                  Cập nhật
                </Button>
              </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
        {compState.itemList.length > 0 &&
          <Pagination
            current={compState.pageIndex}
            total={compState.totalItems}
            pageSize={compState.pageSize}
            showSizeChanger={false}
            onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
          />
         }
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostCommission id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManageCommission;