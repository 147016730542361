import * as Service from "../../common/serviceHelper";

export const LoginService = {
	tokenSet: (token) => Service.tokenSet(token),
	loginSubmit: async (param) => await Service.post("api/user/login/submit", param),
	logoutSubmit: async () => {
		await Service.get("api/user/login/logout");
		Service.tokenRemove();
	},
};

export const LoginToUserService = {
	login: async (param) => await Service.get("api/user/loginToUser/login", param),
	backToLogin: async () => await Service.get("api/user/loginToUser/backToLogin"),
};

export const ProfileService = {
	init: async () => await Service.get("api/user/profile/init"),
	submit: async (param) => await Service.post("api/user/profile/submit", param),
};

export const RegisterService = {
	init: async () => await Service.get("api/user/register/init"),
	submit: async (param) => await Service.post("api/user/register/submit", param),
};