import React, { useEffect, useState } from "react";
import Media from "react-media";
import { Subject } from "rxjs";

import * as Const from "./const";

const subject = new Subject();
export const siderService = {
	visible: (isVisible) => subject.next({ isVisible: isVisible }),
	getObservable: () => subject.asObservable(),
};

const SiderBase = (props) => {
  const [compState, setCompState] = useState({
    isVisible: false
  });

  useEffect(() => {
    const siderSubscription = siderService.getObservable().subscribe((option) => {
      if (!option || option.isVisible == null) option = { isVisible: !compState.isVisible };

			let isSiderChange = false;
			let siderState = {};

			if (option.isVisible !== compState.isVisible) {
				siderState.isVisible = option.isVisible;
				isSiderChange = true;
			}
      if(isSiderChange) setCompState(siderState);

      return () => {
        siderSubscription.unsubscribe();
      }
    })
  },[]);

  const visible = (isVisible) => {
		if (isVisible == null) setCompState({isVisible: !compState.isVisible});
		else if (isVisible !== compState.isVisible) setCompState({ isVisible: isVisible });
	};

  const sider = (visible) => {
		const { colapse } = props;
		const visibleMedia = Const.media.smallQuery;
		if (colapse) return <Media query={visibleMedia} render={() => <aside className={"sider " + visible}>{props.children}</aside>} />;
		else return <aside className={"sider " + visible}>{props.children}</aside>;
	};

  const backdrop = (visible) => {
		return (
			<div
				onClick={() => {
					setCompState({ isVisible: false });
				}}
				className={"backdrop " + visible}
			></div>
		);
	};

  const visibleStr =  compState.isVisible === true ? "visible" : "";
  if (visibleStr) document.body.classList.add("sider-visible");
	else document.body.classList.remove("sider-visible");

  return (
    <React.Fragment>
			{sider(visibleStr)}
			{backdrop(visibleStr)}
		</React.Fragment>
  )
}

export default SiderBase;