import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select, Switch} from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { PostService } from './partnerService';

const PostPartner = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({
    isActive:true
  });
  const [compState, setCompState] = useState({
    title: props.id ? 'Cập nhật' : 'Tạo mới'
  });

  useEffect(() => {
    init();
  }, []);

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await PostService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const init = () => {
    Popup.spin.show();
    PostService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...item
        })
      }
    })
  }

  if(!compState)return <></>
  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col xs={24} sm={8}>
            <label>Tên đối tác</label>
            <Input
							value={itemState.name}
							onChange={(event) => {
								setItemState({ ...itemState, name: event.target.value });
							}}
						/>
            <Msg target="name" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={24} sm={8}>
            <label>Địa chỉ</label>
            <Input
							value={itemState.address}
							onChange={(event) => {
								setItemState({ ...itemState, address: event.target.value });
							}}
						/>
            <Msg target="address" msgs={msgs} className="errorMsg" />
          </Col>
          <Col>
            <label>Số điện thoại</label>
            <Input
              value={itemState.phone}
              onChange={(event) => 
                setItemState({ ...itemState, phone: event.target.value })}
            />
            <Msg target="phone" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24}>
            <label>Mô tả</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.description}
              placeholder="Mô tả chi tiết"
              onChange={({ target: { value } }) => setItemState({ ...itemState, description: value })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Switch checkedChildren="Hoạt động" unCheckedChildren="Ngưng hoạt động" checked={itemState.isActive}
              onChange={(checked) => setItemState({...itemState, isActive: checked})}
            ></Switch>
          </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostPartner;