import React, { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { Spin, BackTop } from "antd";

import { Popup } from "./common/component";
import { default as LayoutBE } from "./component/backend/layout";

import "./theme/app.scss";

function App() {
  const [isSpinVisible, setIsSpinVisible] = useState(false);

  useEffect(() => {
    const spinSubscription = Popup.spin.getObservation().subscribe((option) => {
      if (!option || option.isVisible == null) option = { isVisible: !isSpinVisible };
      setIsSpinVisible(option.isVisible);
    });
    return () => {
      spinSubscription.unsubscribe();
    };
  }, []);
  
  return (
    <Spin spinning={isSpinVisible} size="large" wrapperClassName="spin">
			<Routes>
				<Route path="*" element={<LayoutBE/>} />
			</Routes>
			<BackTop />
		</Spin>
  );
}

export default App;
