
import * as Service from "../../common/serviceHelper";

export const OperatingCostService = {
  init: async param => await Service.get("api/operatingcost/post/init", param),
  submit: async param => await Service.post("api/operatingcost/post/submit", param),
  delete: async param => await Service.post("api/operatingcost/post/delete", param),
}

export const OperatingCostManageService = {
  init: async param => await Service.get("api/operatingcost/manage/getoperatingcost", param),
}