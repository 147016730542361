import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Switch, Button, Pagination, Dropdown, Select, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputComp } from "../../common/component";

import { PartnerManageService } from './partnerService';
import { CurrentUserService } from "../user/currentUserService";

import PostPartner from "./post";

const { RangePicker } = DatePicker;
const today = moment();

const ManagePartner = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    itemList: [],
    defaultToday:today
  });

  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    isActive: true
  });

  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCurrentUserState({currentUser: user});
		});

		return () => {
			userCurrentSubscription.unsubscribe();
		}
  }, []);

  useEffect(() => {
    init();
  }, [filterState]);

  const init = () => {
    Popup.spin.show();

    let filterParams = {
      ...filterState,
    };

    PartnerManageService.init({ ...filterParams }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onChangeSearchPartner = (event) => {
    setFilterState({
      ...filterState,
      searchKey: event.target.value ,
      includeOrderLatest: true,
    })
  }

  const onChangeStatusPartner = (active) => {
    setFilterState({
      ...filterState,
      isActive: active ? active : null ,
    })
  }

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  }

  const onItemOk = () => {
    init();
    setItemState({
      isItemDrawer: false,
    });
  };

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Đối tác</h1>
      <Row align="middle" justify="end">
        <Col>Số lượng đối tác: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col>
          <InputComp placeholder="Tìm tên hoặc SĐT" onChange={onChangeSearchPartner} value={filterState.searchKey}/>
        </Col>
        <Col>
          <Switch checkedChildren="Hoạt động" unCheckedChildren="Tất cả" defaultChecked onChange={onChangeStatusPartner}></Switch>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
        {compState.itemList.length > 0 &&
          <Pagination
            current={compState.pageIndex}
            total={compState.totalItems}
            pageSize={compState.pageSize}
            showSizeChanger={false}
            onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
          />
        }
        </Col>
      </Row>
      <div className="box">
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Tên</li>
            <li>Địa chỉ</li>
            <li>Ghi chú</li>
            <li>Lần đặt gần đây</li>
            <li>Hoạt động</li>
            <li></li>
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <div>
                  <strong>
                    <a onClick={() => onEditItem(item)}>{item.name}</a>
                  </strong>
                </div>
                <div>
                  {item.phone && item.phone !== '0' ? <em><strong>{item.phone}</strong></em> : ''}
                </div>
              </li>
              <li>
                {item.address}
              </li>
              <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}>
              </li>
              <li>
                {item.orderLatest ? Format.date(new Date(item.orderLatest)) : ""}
              </li>
              <li>
                <Switch checked={item.isActive}></Switch>
              </li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li style={{float:'right'}}>
                <Button type="primary" onClick={() => onEditItem(item)}>
                  Cập nhật
                </Button>
              </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostPartner id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManagePartner;