
import * as Service from "../../common/serviceHelper";

export const ReportManageService = {
  init: async (param) => await Service.get("api/dasboard/report/getbill", param),
  getIncomeMonth: async (param) => await Service.get("api/dasboard/report/getIncomeMonth", param),
  getIncomeYear: async (param) => await Service.get("api/dasboard/report/getIncomeYear", param),

  //Report
  getReportInYear: async (param) => await Service.get("api/dasboard/report/getReportInYear", param),
  getReportMotorbike: async (param) => await Service.get("api/dasboard/report/getReportMotorbike", param),
  getReportResourceInYear: async (param) => await Service.get("api/dasboard/report/getReportResourceInYear", param),
  getCompareGrossIncomeInMonth: async (param) => await Service.get("api/dasboard/report/getGrossIncomeToDateInMonth", param)
}