import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Dropdown, DatePicker, Menu } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { ReportManageService } from './reportService';

const IncomeMonth = () => {
  const [compState, setCompState] = useState({
    itemList: [],
  });
  const [itemState, setItemState] = useState({
    pageIndex: 1,
    year: moment().year()
  })

  useEffect(() => {
    getList();
  }, [itemState]);

  const getList = () => {
    Popup.spin.show();
    ReportManageService.getIncomeMonth({
      pageIndex: itemState.pageIndex, isPaid: true,
      year: itemState.year
    }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          itemList: res.itemList,
          totalGrossIncomeValue: res.totalGrossIncomeValue,
          totalCommissionValue: res.totalCommissionValue,
          totalMaintenanceValue: res.totalMaintenanceValue,
          totalOperatingCostValue: res.totalOperatingCostValue,
          totalIncomeValue: res.totalIncomeValue,
        });
      }
    })
  }

  const onChangeYear = (value) => {
    if (value) {
      setItemState({
        ...itemState,
        year: value.year(),
      });
    }
  }

  const onFunctionUpdate = (e) => {
    e.preventDefault();

    Popup.info({
      title: "Tính năng đang tạm khoá",
    });
  };

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Doanh thu theo tháng</h1>
      <Row align="middle" justify="start">
        <Col>
          Của năm: <DatePicker defaultValue={moment()} onChange={onChangeYear} picker="year" allowClear={false}/>
        </Col>
      </Row>
      <div className="box">
        <div className="title-box">
          <h3>Tổng thu: <strong className="income">{Format.number(compState.totalGrossIncomeValue)}</strong></h3>
          <h3>Hoa hồng: <strong className="commission">{Format.number(compState.totalCommissionValue)}</strong></h3>
          <h3>Doanh thu: <strong className="income">{Format.number(compState.totalGrossIncomeValue - compState.totalCommissionValue)}</strong></h3>
          <h3>Bảo trì: <strong className="commission">{Format.number(compState.totalMaintenanceValue)}</strong></h3>
          <h3>Chí phí hoạt động: <strong className="commission">{Format.number(compState.totalOperatingCostValue)}</strong></h3>
          <h3>Lợi nhuận: <strong className="profit">{Format.number(compState.totalIncomeValue)}</strong></h3>
        </div>
        <div className="table has-left-col has-menu">
          <ol>
            <li>Tháng</li>
            <li>Tổng thu</li>
            <li>Hoa hồng (-)</li>
            <li>Doanh thu</li>
            <li>Bảo trì (-)</li>
            <li>Chi phí hoạt động (-)</li>
            <li>Lợi nhuận</li>
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={index}>
              <li className="id-col collapsing">{item.month}</li>
              <li className="income">
                <strong>{Format.number(item.grossIncomeValue)}</strong>
              </li>
              <li className="commission">
                {Format.number(item.commissionValue)}
              </li>
              <li className="income">
                {Format.number(item.grossIncomeValue - item.commissionValue)}
              </li>
              <li className="commission">
                {Format.number(item.maintenanceValue)}
              </li>
              <li className="commission">
                {Format.number(item.operatingCostValue)}
              </li>
              <li className="profit">
                <strong>{Format.number(item.incomeValue)}</strong>
              </li>
            </ul>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default IncomeMonth;