
import * as Service from "../../common/serviceHelper";

export const CommissionManageService = {
  init: async (param) => await Service.get("api/commission/manage/init", param),
  getList: async (param) => await Service.get("api/commission/manage/getcommissionlist", param),
}

export const PostService = {
  init: async (param) => await Service.get("api/commission/post/init", param),
  submit: async (param) => await Service.post("api/commission/post/submit", param),
}