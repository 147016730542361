import React, { useEffect, useState } from "react";
import { Row, Col, Button, Pagination, Dropdown, DatePicker, Menu, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { OperatingCostManageService, OperatingCostService } from './operatingCostService';
import { CurrentUserService } from "../user/currentUserService";
import OperatingCostPost from './post';

const today = moment();

const ManageOperatingCost = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    itemList: [],
    defaultToday: today
  });
  const [filterState, setFilterState] = useState({
    month: today.format('M'),
    year: today.format('YYYY'),
    pageIndex: 1,
    orderBy: 'DateDec',
  });

  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
      setCurrentUserState({ currentUser: user });
    });

    return () => {
      userCurrentSubscription.unsubscribe();
    }
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  const getList = () => {
    Popup.spin.show();

    let filterParams = {
      ...filterState,
    };
    OperatingCostManageService.init({ ...filterParams }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          totalValue: res.totalValue,
          totalPaymentValue: res.totalPaymentValue,
          totalIncurredValue: res.totalIncurredValue,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onChangePicker = (date) => {
    setFilterState({
      ...filterState,
      month: date.format('M'),
      year: date.format('YYYY')
    })
  }

  const onFunctionUpdate = (e) => {
    e.preventDefault();

    Popup.info({
      title: "Tính năng đang tạm khoá",
    });
  };

  const onAddItem = () => {
    setItemState({
      isItemDrawer: true,
    });
  }

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      isItemDrawer: false,
    });
  };

  const deleteItem = (item) => {
    Popup.spin.show();
    OperatingCostService.delete(item.id).then((res) => {
      Popup.spin.hide();
      getList();
    });
  };

  const onRemoveItem = (item) => {
    Popup.confirm({
      title: Locale.getText("common.delete_confirm"),
      content: item.name,
      onOk: () => deleteItem(item),
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  };

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Chi phí hoạt động</h1>
      <Row align="middle">
        <Col>
          <span>Tháng </span>
          <DatePicker
            defaultValue={moment()}
            format={'MM/YYYY'}
            onChange={onChangePicker}
            allowClear={false}
            picker="month"
          />
        </Col>
      </Row>
      <Row align="middle" justify="end">
        <Col>Số lượng: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        {currentUserState.currentUser.canEdit && <>
          <Col style={{ marginLeft: 'auto' }}>
            <Button type="primary" onClick={onAddItem}>
              <i className="fa fa-plus" />
              <span>Thêm</span>
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onFunctionUpdate}>
              <i className="far fa-copy" />
              <span>Tạo nhanh tháng mới</span>
            </Button>
          </Col>
        </>}
        <Col>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        <div className="title-box">
          <h3>Chi phí: <strong className="profit">{Format.number(compState.totalPaymentValue)}</strong></h3>
          <h3>Chi phí phát sinh: <strong className="commission">{Format.number(compState.totalIncurredValue)}</strong></h3>
          <h3>Tổng chi phí: <strong className="income">{Format.number(compState.totalValue)}</strong></h3>
        </div>
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Tên</li>
            <li>Mô tả</li>
            <li>Ngày tạo</li>
            <li>Ngày cập nhật</li>
            <li>Số tiền</li>
            <li>Phát sinh</li>
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <strong>
                  <a onClick={() => onEditItem(item)}>{item.name}</a>
                </strong>
              </li>
              <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}></li>
              <li>
                {Format.date(new Date(item.dateCreated))}
              </li>
              <li>
                {item.dateUpdated && Format.date(new Date(item.dateUpdated))}
              </li>
              <li>
                <strong>{Format.number(item.payment)}</strong>
              </li>
              <li>
                <strong>{Format.number(item.incurred)}</strong>
              </li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit &&  <li className="menu-col">
                <Dropdown
                  placement="bottomRight"
                  trigger={["click"]}
                  overlay={
                    <Menu items={[
                      {
                        key: 'update',
                        label: <a onClick={() => onEditItem(item)}>
                          <span>Cập nhật</span>
                        </a>,
                        icon: <i className="icon fal fa-pen"></i>
                      },
                      {
                        key: 'delete',
                        label: <a onClick={() => onRemoveItem(item)}>
                          <span>Xóa</span>
                        </a>,
                        icon: <i className="icon fal fa-trash-alt"></i>
                      }
                    ]}>
                    </Menu>
                  }
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <i className="far fa-ellipsis-v" />
                  </a>
                </Dropdown>
              </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value }, () => getList())}
            />
          }
        </Col>
      </Row>

      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <OperatingCostPost id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManageOperatingCost;