import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button} from "antd";

import { Popup, Msg } from "../../common/component";

import { ProfileService } from './userService';
import { CurrentUserService } from './currentUserService';

const Profile = () => {
  const [msgs, setMsgs] = useState([]);
	const [compState, setCompState] = useState({
		userTypeName:''
	});
  const [itemState, setItemState] = useState({
    firstName: "",
    lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
  });

  useEffect(() => {
    ProfileService.init().then((res) => {
			if (res) {
        var item = res.item;
				setItemState({
					isAdmin: res.isAdmin,
					userName: res.userName,
					firstName: item.firstName,
          lastName: item.lastName,
					phone: item.phone,
					email: item.email,
				});
				setCompState({
					userTypeName: item.userTypeName
				});
			}
			Popup.spin.hide();
		});
  }, []);

	const onSubmit = async () => {
		Popup.spin.show();
		const res = await ProfileService.submit({
			firstName: itemState.firstName,
      lastName: itemState.lastName,
			phoneNumber: itemState.phone,
			email: itemState.email,
			password: itemState.password,
			confirmPassword: itemState.confirmPassword,
		});
		Popup.spin.hide();

		if (res.success) {
			CurrentUserService.checkUser();
			Popup.info({ autoClose: true });
		} else setMsgs(res.msgs);
	};

	return (
		<div className="profile-page">
			<h1>Xin chào {compState.userTypeName}</h1>
			<input type="text" className="input-hidden" />
			<input type="password" className="input-hidden" />
			<div className="form">
				<Row>
					<Col xs={{ span: 24, order: 2 }} lg={{ span: 15, order: 1 }}>
						<div className="box">
							<div className="title">Thông tin cơ bản</div>
							<Row>
								<Col xs={24} sm={12}>
									<label>Tên đăng nhập</label>
									<Input value={itemState.userName} readOnly />
								</Col>
								<Col xs={24} sm={12}>
									<label>Email</label>
									<Input
										value={itemState.email}
										onChange={(event) => {
											setItemState({...itemState, email: event.target.value });
										}}
									/>
									<Msg target="email" msgs={msgs} className="errorMsg" />
								</Col>
							</Row>
							<Row>
								<Col xs={24} sm={12}>
									<label>Họ</label>
									<Input
										value={itemState.firstName}
										onChange={(event) => {
											setItemState({ ...itemState, firstName: event.target.value });
										}}
									/>
									<Msg target="firstName" msgs={msgs} className="errorMsg" />
								</Col>
								<Col xs={24} sm={12}>
                <label>Tên</label>
									<Input
										value={itemState.lastName}
										onChange={(event) => {
											setItemState({ ...itemState, lastName: event.target.value });
										}}
									/>
									<Msg target="lastName" msgs={msgs} className="errorMsg" />
								</Col>
							</Row>
							<Row>
								<Col>
									<label>Mật khẩu mới</label>
									<Input.Password
										value={itemState.password}
										onChange={(event) => {
											setItemState({ ...itemState, password: event.target.value });
										}}
									/>
									<Msg target="password" msgs={msgs} className="errorMsg" />
								</Col>
								<Col>
									<label>Nhập lại mật khẩu mới</label>
									<Input.Password
										value={itemState.confirmPassword}
										onChange={(event) => {
											setItemState({ ...itemState, confirmPassword: event.target.value });
										}}
									/>
									<Msg target="confirmPassword" msgs={msgs} className="errorMsg" />
								</Col>
							</Row>
							<Row>
								<Col>
									<em>
										- Mật khẩu ít nhất 8 ký tự
										<br />
										- Phải có CHỮ HOA, chữ thường, con số
										<br />
										- Và các ký tự đặc biệt: ! @, #, $, %, ^, &amp;, *<br />
									</em>
								</Col>
							</Row>
							<div className="divider" />
							<Row>
								<Col>
									<Button type="primary" onClick={onSubmit}>
										Cập nhật
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default Profile;