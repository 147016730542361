import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select } from "antd";
import {
  useParams,
  useNavigate
} from "react-router-dom";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputNumber } from "../../common/component";

import { MotorBikeManageService } from '../motorBike/motorBikeService';

const today = moment();

const MotorbikeDetail = (props) => {
  const { extId } = useParams();
  const [itemState, setItemState] = useState({
    extId: extId,
  });
  const [compState, setCompState] = useState(null);
  const [filterState, setFilterState] = useState({
    extId: extId,
    fromDate: today.format('YYYY-MM-DD')
  });

  const [distanceState, setDistanceState] = useState({
    extId: extId,
    fromDate: null, //today.format('YYYY-MM-DD'),
    toDate: null, //today.format('YYYY-MM-DD'),
  });

  let navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getDetailInMonth();
  }, [filterState]);

  useEffect(() => {
    getDistance();
  }, [distanceState])

  const init = () => {
    Popup.spin.show();
    MotorBikeManageService.detail({ ...itemState }).then(res => {
      Popup.spin.hide();
      if (res.success) {
        setCompState({
          ...compState,
          item: res.item
        });
      }
    })
  }

  const getDetailInMonth = () => {
    Popup.spin.show();
    MotorBikeManageService.detailInMonth({ ...filterState }).then(res => {
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          totalIncomeValueInMonth: res.item.totalIncomeValueInMonth,
          totalMaintenanceValueInMonth: res.item.totalMaintenanceValueInMonth,
          coutingHireInMonth: res.item.coutingHireInMonth
        })
      }
    })
  }

  const getDistance = () => {
    Popup.spin.show();
    MotorBikeManageService.getDistance({ ...distanceState }).then(res => {
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          totalDistance: res.distanceKmUnit
        })
      }
    })
  }

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = () => {
    props.onOk();
  }

  const onChangePicker = (date) => {
    if(!date) return;
    setFilterState({
      ...filterState,
      fromDate: date.format('YYYY-MM-DD'),
    })
  }

  const onChangeFromDatepicker = (date) => {
    setDistanceState({
      ...distanceState,
      fromDate: date != null ? date.format("YYYY-MM-DD") : null,
    });
  }

  const onChangeToDatepicker = (date) => {
    setDistanceState({
      ...distanceState,
      toDate: date != null ? date.format("YYYY-MM-DD") : null,
    });
  }

  if(!compState) return <></>
  return (
    <React.Fragment>
      <Row>
        <Col>
        <Button onClick={() => navigate(-1)} icon={<i className="icon fal fa-arrow-left"></i>}>
          Quay lại
        </Button>
        </Col>
      </Row>
      <div className="main">
        <Row>
          <Col>
            <h2>Xe {extId}</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={24} sm={24}>
            <div className="box" style={{minHeight:'20em'}}>
              <div className="title-box">
                <h2>Thông tin tổng quát</h2>
              </div>
              <div>
                Loại xe: <strong>{compState.item.type}</strong>
              </div>
              <div>
                Ngày mua: <strong>{Format.date(new Date(compState.item.created))}</strong>
              </div>
              <div>
                Trị giá: <strong>{Format.number(compState.item.assetCost)}</strong>
              </div>
              <div>
                Doanh thu: <strong>{Format.number(compState.item.totalIncomeValue)}</strong>
              </div>
              <div>
                Bảo trì: <strong>{Format.number(compState.item.totalMaintenanceValue)}</strong>
              </div>
              <div>
                Số lần thuê: <strong>{Format.number(compState.item.coutingHire)}</strong>
              </div>
            </div>
          </Col>
          <Col md={6} xs={24} sm={24}>
            <div className="box" style={{minHeight:'20em'}}>
              <div className="title-box">
                <h2>Doanh thu tháng</h2>
                <label>
                <DatePicker
                  defaultValue={moment()}
                  format={'MM/YYYY'}
                  onChange={onChangePicker}
                  allowClear={false}
                  picker="month"
                />
                </label>
              </div>
              <div>
                Doanh thu: <strong>{Format.number(itemState.totalIncomeValueInMonth)}</strong>
              </div>
              <div>
                Bảo trì: <strong>{Format.number(itemState.totalMaintenanceValueInMonth)}</strong>
              </div>
              <div>
                Số lần thuê: <strong>{Format.number(itemState.coutingHireInMonth)}</strong>
              </div>
            </div>
          </Col>
          <Col md={6} xs={24} sm={24}>
            <div className="box" style={{minHeight:'20em'}}>
              <div className="title-box">
                <h2>Tổng số km</h2>
              </div>
              <div>
                <div>
                  <DatePicker
                    placeholder="Từ ngày"
                    format={Locale.getFormat().dateFormat}
                    onChange={onChangeFromDatepicker}
                    allowClear={true}
                    style={{marginRight:'1rem'}}
                    />
                  <DatePicker
                    placeholder="Đến ngày"
                    format={Locale.getFormat().dateFormat}
                    onChange={onChangeToDatepicker}
                    allowClear={true}
                    />
                </div>
                <div style={{marginTop:'1rem'}}>
                  Tổng số km: <strong>{Format.number(itemState.totalDistance)}</strong>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default MotorbikeDetail;