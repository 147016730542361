import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Dropdown, DatePicker, Menu, Input, Select } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { ReportManageService } from './reportService';

const { RangePicker } = DatePicker;
const today = moment();

const Income = () => {
  const [compState, setCompState] = useState({
    itemList:[],
    defaultToday:today
  });
  const [itemState, setItemState] = useState({
    pageIndex: 1,
    orderBy: 'DateDec',
    fromDate: today.format("YYYY-MM-DD"),
		toDate: today.format("YYYY-MM-DD"),
  })

  useEffect(() => {
    getList();
  }, [itemState]);

  const getList = () => {
    Popup.spin.show();
    ReportManageService.init({ ...itemState, isPaid: true}).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          itemList: res.itemList,
          totalGrossIncomeValue: res.totalGrossIncomeValue,
          totalIncomeValue: res.totalIncomeValue,
          totalCommissionValue: res.totalCommissionValue,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onChangeRangepicker = (dates) => {
    if(dates.length > 1){
      setItemState({
        ...itemState,
        fromDate: dates[0].format("YYYY-MM-DD"),
        toDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  }

  const onSearch = (value) => {
    setItemState({
      ...itemState,
      extId: value
    });
  }

  const onFunctionUpdate = (e) => {
		e.preventDefault();

		Popup.info({
			title: "Tính năng đang tạm khoá",
		});
	};

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Doanh thu theo ngày</h1>
      <Row align="middle" justify="end">
        <Col>Số lượng hóa đơn: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col>
          <RangePicker
            defaultValue={[compState.defaultToday, compState.defaultToday]}
            allowEmpty={[false,false]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRangepicker}
            allowClear={false}
            />
        </Col>
        <Col>
          <Select
            placeholder="Loại xe"
            style={{ width: "160px" }}
            value={itemState.kind}
            onChange={(value) => setItemState({ ...itemState, kind: value })}
          >
            <Select.Option value="0">Tất cả loại xe</Select.Option>
            <Select.Option value="1">Xe ga</Select.Option>
            <Select.Option value="2">Xe số</Select.Option>
            <Select.Option value="3">Xe điện</Select.Option>
          </Select>
        </Col>
        <Col>
          <Input.Search
            placeholder="Biển số xe"
  					onSearch={onSearch}
            enterButton
            allowClear
  				/>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setItemState({ ...itemState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        <div className="title-box">
          <h3>Tổng thu: <strong className="income">{Format.number(compState.totalGrossIncomeValue)}</strong></h3>
          <h3>Hoa hồng: <strong className="commission">{Format.number(compState.totalCommissionValue)}</strong></h3>
          <h3>Doanh thu: <strong className="profit">{Format.number(compState.totalIncomeValue)}</strong></h3>
        </div>
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Số xe</li>
            <li>Ngày thuê</li>
            <li>Ngày trả</li>
            <li>Số tiền</li>
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <div>
                  <strong>
                    <a onClick={() => this.onEditItem(item)}>{item.name}</a>
                  </strong>
                </div>
                <em><a onClick={() => this.onEditItem(item)}>{item.extId}</a></em> 
              </li>
              <li>
                <div>{item.dateCreated ? Format.date(new Date(item.dateCreated)) : ""}</div>
                <div>{item.dateCreated ? Format.time(new Date(item.dateCreated)) : ""}</div>
              </li>
              <li>
                <div>{item.dateReturn ? Format.date(new Date(item.dateReturn)) : ""}</div>
                <div>{item.dateReturn ? Format.time(new Date(item.dateReturn)) : ""}</div>
              </li>
              <li>
                <div><strong>{Format.number(item.payment)}</strong></div>
                <em>{Format.number(item.price)}</em>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setItemState({ ...itemState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Income;