import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";

import 'leaflet/dist/leaflet.css'
import L, { Icon } from 'leaflet'
import { MapContainer, TileLayer, useMap, Marker, Popup, Polyline, Tooltip } from 'react-leaflet'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const center = {
  lat: 10.823,
  lng: 106.629,
}

//const center = [51.505, -0.09]

const polyline = [
  [
    [
      10.784418,
      106.639748
      
    ],
    [
      10.78452,
      106.639685
      
    ],
    [
      10.776563,
      106.702531
    ]
  ]
]

const reOrderlatlong = (arr) => {
  return arr.map(item => {
    return [item[1], item[0]]
  })
}

const responseGeo = {
  "features": [
    {
      "type": "Feature",
      "properties": {
        "mode": "drive",
        "waypoints": [
          {
            "location": [
              106.639741,
              10.784414
            ],
            "original_index": 0
          },
          {
            "location": [
              106.703219,
              10.775747
            ],
            "original_index": 1
          }
        ],
        "units": "metric",
        "distance": 10416,
        "distance_units": "meters",
        "time": 795.158,
        "legs": [
          {
            "distance": 10416,
            "time": 795.158,
            "steps": [
              {
                "from_index": 0,
                "to_index": 1,
                "distance": 13,
                "time": 4.786,
                "instruction": {
                  "text": "Drive northwest."
                }
              },
              {
                "from_index": 1,
                "to_index": 11,
                "distance": 263,
                "time": 27.408,
                "instruction": {
                  "text": "Turn right onto Đường Thoại Ngọc Hầu/Duong Thoai Ngoc Hau."
                }
              },
              {
                "from_index": 11,
                "to_index": 28,
                "distance": 796,
                "time": 64.022,
                "instruction": {
                  "text": "Turn left onto Đường Âu Cơ/Au Co."
                }
              },
              {
                "from_index": 28,
                "to_index": 54,
                "distance": 787,
                "time": 72.997,
                "instruction": {
                  "text": "Turn right onto Trương Công Định/Truong Cong Dinh."
                }
              },
              {
                "from_index": 54,
                "to_index": 84,
                "distance": 1375,
                "time": 98.498,
                "instruction": {
                  "text": "Turn right onto Trường Chinh."
                }
              },
              {
                "from_index": 84,
                "to_index": 129,
                "distance": 1189,
                "time": 78.046,
                "instruction": {
                  "text": "Turn left onto Hoàng Văn Thụ."
                }
              },
              {
                "from_index": 129,
                "to_index": 135,
                "distance": 42,
                "time": 6.993,
                "instruction": {
                  "text": "Enter Vòng xoay Lăng Cha Cả/Hoang Van Thu and take the 1st exit onto Hoàng Văn Thụ/Hoang Van Thu."
                }
              },
              {
                "from_index": 135,
                "to_index": 149,
                "distance": 690,
                "time": 46.919,
                "instruction": {
                  "text": "Exit the roundabout onto Hoàng Văn Thụ/Hoang Van Thu."
                }
              },
              {
                "from_index": 149,
                "to_index": 195,
                "distance": 1414,
                "time": 95.873,
                "instruction": {
                  "text": "Keep left to stay on Hoàng Văn Thụ."
                }
              },
              {
                "from_index": 195,
                "to_index": 219,
                "distance": 880,
                "time": 59.491,
                "instruction": {
                  "text": "Turn right onto Phan Đình Phùng/Phan Dinh Phung Street."
                }
              },
              {
                "from_index": 219,
                "to_index": 220,
                "distance": 3,
                "time": 2.494,
                "instruction": {
                  "text": "Turn right to take the ramp."
                }
              },
              {
                "from_index": 220,
                "to_index": 279,
                "distance": 2544,
                "time": 186.71,
                "instruction": {
                  "text": "Turn left onto Phan Đình Phùng."
                }
              },
              {
                "from_index": 279,
                "to_index": 283,
                "distance": 189,
                "time": 18.933,
                "instruction": {
                  "text": "Turn right onto Đường Lê Thánh Tôn/Le Thanh Ton Street."
                }
              },
              {
                "from_index": 283,
                "to_index": 292,
                "distance": 226,
                "time": 31.981,
                "instruction": {
                  "text": "Turn left onto Đường Ðồng Khởi/Dong Khoi Street."
                }
              },
              {
                "from_index": 292,
                "to_index": 292,
                "distance": 0,
                "time": 0,
                "instruction": {
                  "text": "Your destination is on the right."
                }
              }
            ]
          }
        ]
      },
      "geometry": {
        "type": "MultiLineString",
        "coordinates": [
          [
            [
              106.639748,
              10.784418
            ],
            [
              106.639685,
              10.78452
            ],
            [
              106.639953,
              10.784716
            ],
            [
              106.640169,
              10.784875
            ],
            [
              106.640211,
              10.784906
            ],
            [
              106.640249,
              10.784934
            ],
            [
              106.640684,
              10.785253
            ],
            [
              106.64082,
              10.785353
            ],
            [
              106.640967,
              10.785461
            ],
            [
              106.641056,
              10.785527
            ],
            [
              106.641512,
              10.785871
            ],
            [
              106.641611,
              10.785945
            ],
            [
              106.641574,
              10.78604
            ],
            [
              106.641449,
              10.786365
            ],
            [
              106.641165,
              10.78706
            ],
            [
              106.641113,
              10.787232
            ],
            [
              106.640936,
              10.787738
            ],
            [
              106.640885,
              10.787871
            ],
            [
              106.640813,
              10.788117
            ],
            [
              106.64077,
              10.788266
            ],
            [
              106.640602,
              10.788759
            ],
            [
              106.640457,
              10.78922
            ],
            [
              106.640408,
              10.789371
            ],
            [
              106.640163,
              10.790033
            ],
            [
              106.640096,
              10.790227
            ],
            [
              106.639836,
              10.79105
            ],
            [
              106.639666,
              10.791534
            ],
            [
              106.639353,
              10.792421
            ],
            [
              106.639263,
              10.79271
            ],
            [
              106.639316,
              10.792734
            ],
            [
              106.639658,
              10.792832
            ],
            [
              106.639835,
              10.792925
            ],
            [
              106.639931,
              10.792993
            ],
            [
              106.640015,
              10.793061
            ],
            [
              106.640115,
              10.793173
            ],
            [
              106.640156,
              10.793254
            ],
            [
              106.640184,
              10.793312
            ],
            [
              106.640216,
              10.793408
            ],
            [
              106.640298,
              10.793732
            ],
            [
              106.640325,
              10.793836
            ],
            [
              106.640515,
              10.794491
            ],
            [
              106.64061,
              10.794818
            ],
            [
              106.640715,
              10.795178
            ],
            [
              106.640827,
              10.795603
            ],
            [
              106.640928,
              10.795892
            ],
            [
              106.64096,
              10.795983
            ],
            [
              106.641099,
              10.796391
            ],
            [
              106.64116,
              10.796589
            ],
            [
              106.641227,
              10.796806
            ],
            [
              106.641262,
              10.796909
            ],
            [
              106.641456,
              10.797489
            ],
            [
              106.641515,
              10.797663
            ],
            [
              106.64154,
              10.797743
            ],
            [
              106.641718,
              10.79831
            ],
            [
              106.641931,
              10.798993
            ],
            [
              106.642766,
              10.798549
            ],
            [
              106.642878,
              10.798489
            ],
            [
              106.643018,
              10.798415
            ],
            [
              106.643317,
              10.798255
            ],
            [
              106.643723,
              10.798039
            ],
            [
              106.644016,
              10.797881
            ],
            [
              106.644379,
              10.797686
            ],
            [
              106.644769,
              10.797477
            ],
            [
              106.644956,
              10.797377
            ],
            [
              106.645148,
              10.797274
            ],
            [
              106.645378,
              10.79715
            ],
            [
              106.645449,
              10.797112
            ],
            [
              106.645859,
              10.796892
            ],
            [
              106.646116,
              10.796755
            ],
            [
              106.646212,
              10.796703
            ],
            [
              106.646749,
              10.796414
            ],
            [
              106.646935,
              10.796312
            ],
            [
              106.648467,
              10.7955
            ],
            [
              106.648849,
              10.795288
            ],
            [
              106.650153,
              10.794582
            ],
            [
              106.65032,
              10.794494
            ],
            [
              106.650783,
              10.794252
            ],
            [
              106.650933,
              10.794174
            ],
            [
              106.651592,
              10.793812
            ],
            [
              106.651776,
              10.793749
            ],
            [
              106.651974,
              10.793642
            ],
            [
              106.652246,
              10.793494
            ],
            [
              106.65239,
              10.793415
            ],
            [
              106.652558,
              10.793325
            ],
            [
              106.652985,
              10.793092
            ],
            [
              106.653165,
              10.793067
            ],
            [
              106.653223,
              10.79307
            ],
            [
              106.653326,
              10.793096
            ],
            [
              106.653348,
              10.793109
            ],
            [
              106.653421,
              10.793153
            ],
            [
              106.653567,
              10.793241
            ],
            [
              106.653686,
              10.793379
            ],
            [
              106.653823,
              10.793543
            ],
            [
              106.653978,
              10.793714
            ],
            [
              106.654219,
              10.793917
            ],
            [
              106.654391,
              10.794048
            ],
            [
              106.65454,
              10.794192
            ],
            [
              106.654957,
              10.794591
            ],
            [
              106.65502,
              10.794652
            ],
            [
              106.655054,
              10.794686
            ],
            [
              106.655117,
              10.794754
            ],
            [
              106.655283,
              10.794919
            ],
            [
              106.655363,
              10.794999
            ],
            [
              106.65539,
              10.795027
            ],
            [
              106.655444,
              10.795077
            ],
            [
              106.655459,
              10.795092
            ],
            [
              106.655605,
              10.79523
            ],
            [
              106.65567,
              10.795253
            ],
            [
              106.655719,
              10.795272
            ],
            [
              106.655817,
              10.795353
            ],
            [
              106.655878,
              10.795412
            ],
            [
              106.656421,
              10.795955
            ],
            [
              106.656544,
              10.796072
            ],
            [
              106.657407,
              10.7969
            ],
            [
              106.657655,
              10.797153
            ],
            [
              106.658367,
              10.797885
            ],
            [
              106.658581,
              10.798087
            ],
            [
              106.65898,
              10.798465
            ],
            [
              106.659385,
              10.798849
            ],
            [
              106.659563,
              10.799018
            ],
            [
              106.659738,
              10.799183
            ],
            [
              106.659887,
              10.799324
            ],
            [
              106.660254,
              10.799672
            ],
            [
              106.660293,
              10.799709
            ],
            [
              106.66041,
              10.799824
            ],
            [
              106.660445,
              10.799889
            ],
            [
              106.660539,
              10.79996
            ],
            [
              106.660554,
              10.799971
            ],
            [
              106.660664,
              10.800094
            ],
            [
              106.660835,
              10.800286
            ],
            [
              106.660879,
              10.800259
            ],
            [
              106.660935,
              10.800243
            ],
            [
              106.660987,
              10.800259
            ],
            [
              106.661058,
              10.800297
            ],
            [
              106.661112,
              10.800343
            ],
            [
              106.661146,
              10.800398
            ],
            [
              106.661488,
              10.800575
            ],
            [
              106.661678,
              10.800668
            ],
            [
              106.661771,
              10.800695
            ],
            [
              106.661964,
              10.800731
            ],
            [
              106.662281,
              10.800742
            ],
            [
              106.663509,
              10.800635
            ],
            [
              106.663833,
              10.800557
            ],
            [
              106.665457,
              10.800406
            ],
            [
              106.665578,
              10.800388
            ],
            [
              106.666601,
              10.800301
            ],
            [
              106.666817,
              10.800283
            ],
            [
              106.666894,
              10.800275
            ],
            [
              106.667141,
              10.800247
            ],
            [
              106.667375,
              10.800221
            ],
            [
              106.667764,
              10.800179
            ],
            [
              106.66806,
              10.800123
            ],
            [
              106.668152,
              10.800117
            ],
            [
              106.668525,
              10.800093
            ],
            [
              106.668597,
              10.800086
            ],
            [
              106.668859,
              10.800063
            ],
            [
              106.669214,
              10.800039
            ],
            [
              106.669401,
              10.800018
            ],
            [
              106.669788,
              10.799983
            ],
            [
              106.670228,
              10.799946
            ],
            [
              106.670274,
              10.799942
            ],
            [
              106.671086,
              10.799869
            ],
            [
              106.671449,
              10.799836
            ],
            [
              106.67163,
              10.799819
            ],
            [
              106.671829,
              10.799799
            ],
            [
              106.671961,
              10.799787
            ],
            [
              106.672134,
              10.799772
            ],
            [
              106.672527,
              10.799737
            ],
            [
              106.672821,
              10.79971
            ],
            [
              106.67296,
              10.7997
            ],
            [
              106.673254,
              10.799676
            ],
            [
              106.673609,
              10.799647
            ],
            [
              106.674342,
              10.799586
            ],
            [
              106.674666,
              10.799559
            ],
            [
              106.674736,
              10.799553
            ],
            [
              106.674918,
              10.799538
            ],
            [
              106.675352,
              10.799509
            ],
            [
              106.67603,
              10.79946
            ],
            [
              106.676068,
              10.799457
            ],
            [
              106.676106,
              10.799454
            ],
            [
              106.676375,
              10.799433
            ],
            [
              106.676847,
              10.799394
            ],
            [
              106.677031,
              10.79937
            ],
            [
              106.677564,
              10.799331
            ],
            [
              106.678061,
              10.799279
            ],
            [
              106.678315,
              10.799253
            ],
            [
              106.678483,
              10.799239
            ],
            [
              106.678515,
              10.799236
            ],
            [
              106.678806,
              10.79921
            ],
            [
              106.679562,
              10.799157
            ],
            [
              106.679797,
              10.799141
            ],
            [
              106.67998,
              10.799128
            ],
            [
              106.680015,
              10.799125
            ],
            [
              106.680104,
              10.799125
            ],
            [
              106.680203,
              10.799161
            ],
            [
              106.680278,
              10.799187
            ],
            [
              106.68031,
              10.799116
            ],
            [
              106.680581,
              10.798518
            ],
            [
              106.680935,
              10.797712
            ],
            [
              106.681165,
              10.797194
            ],
            [
              106.681335,
              10.796821
            ],
            [
              106.681472,
              10.796473
            ],
            [
              106.681607,
              10.796217
            ],
            [
              106.681903,
              10.79586
            ],
            [
              106.681958,
              10.795816
            ],
            [
              106.682049,
              10.795745
            ],
            [
              106.682203,
              10.795623
            ],
            [
              106.682429,
              10.795442
            ],
            [
              106.682624,
              10.795288
            ],
            [
              106.683044,
              10.794951
            ],
            [
              106.683299,
              10.794753
            ],
            [
              106.683462,
              10.794625
            ],
            [
              106.683656,
              10.794471
            ],
            [
              106.683941,
              10.794251
            ],
            [
              106.684298,
              10.793966
            ],
            [
              106.684414,
              10.793871
            ],
            [
              106.684448,
              10.793845
            ],
            [
              106.68462,
              10.79371
            ],
            [
              106.684875,
              10.793506
            ],
            [
              106.685206,
              10.793243
            ],
            [
              106.685188,
              10.793221
            ],
            [
              106.685506,
              10.792974
            ],
            [
              106.685611,
              10.792888
            ],
            [
              106.686651,
              10.79204
            ],
            [
              106.687209,
              10.791615
            ],
            [
              106.687339,
              10.791506
            ],
            [
              106.687465,
              10.791457
            ],
            [
              106.687675,
              10.791289
            ],
            [
              106.687904,
              10.791107
            ],
            [
              106.687993,
              10.791036
            ],
            [
              106.688343,
              10.790757
            ],
            [
              106.688414,
              10.790701
            ],
            [
              106.688612,
              10.79054
            ],
            [
              106.688713,
              10.790458
            ],
            [
              106.688896,
              10.790313
            ],
            [
              106.689046,
              10.790196
            ],
            [
              106.689318,
              10.78998
            ],
            [
              106.689513,
              10.789824
            ],
            [
              106.689596,
              10.789758
            ],
            [
              106.689925,
              10.789496
            ],
            [
              106.690034,
              10.789409
            ],
            [
              106.690236,
              10.78925
            ],
            [
              106.690411,
              10.78911
            ],
            [
              106.690639,
              10.788927
            ],
            [
              106.690768,
              10.788824
            ],
            [
              106.691185,
              10.788488
            ],
            [
              106.691612,
              10.78815
            ],
            [
              106.69194,
              10.78789
            ],
            [
              106.692824,
              10.787191
            ],
            [
              106.692887,
              10.787141
            ],
            [
              106.693711,
              10.78649
            ],
            [
              106.694073,
              10.786204
            ],
            [
              106.694314,
              10.786013
            ],
            [
              106.694426,
              10.785925
            ],
            [
              106.694583,
              10.785801
            ],
            [
              106.694728,
              10.785683
            ],
            [
              106.694866,
              10.785577
            ],
            [
              106.69594,
              10.784701
            ],
            [
              106.695984,
              10.784663
            ],
            [
              106.696035,
              10.784618
            ],
            [
              106.69648,
              10.784258
            ],
            [
              106.696636,
              10.784125
            ],
            [
              106.696794,
              10.78399
            ],
            [
              106.696908,
              10.783893
            ],
            [
              106.696974,
              10.783836
            ],
            [
              106.697118,
              10.783709
            ],
            [
              106.697291,
              10.783556
            ],
            [
              106.698069,
              10.782869
            ],
            [
              106.698764,
              10.782269
            ],
            [
              106.698922,
              10.782133
            ],
            [
              106.699573,
              10.781534
            ],
            [
              106.699636,
              10.781476
            ],
            [
              106.700121,
              10.781039
            ],
            [
              106.70016,
              10.781005
            ],
            [
              106.700454,
              10.780744
            ],
            [
              106.70106,
              10.780187
            ],
            [
              106.701679,
              10.779657
            ],
            [
              106.702175,
              10.779197
            ],
            [
              106.702536,
              10.778873
            ],
            [
              106.702962,
              10.77849
            ],
            [
              106.702551,
              10.778033
            ],
            [
              106.702152,
              10.777589
            ],
            [
              106.701853,
              10.777288
            ],
            [
              106.701798,
              10.777234
            ],
            [
              106.701846,
              10.777189
            ],
            [
              106.702531,
              10.776563
            ],
            [
              106.702561,
              10.776536
            ],
            [
              106.702663,
              10.776442
            ],
            [
              106.702725,
              10.776386
            ],
            [
              106.702914,
              10.776212
            ],
            [
              106.702967,
              10.776164
            ],
            [
              106.703117,
              10.776027
            ],
            [
              106.703313,
              10.775847
            ]
          ]
        ]
      }
    }
  ],
  "properties": {
    "mode": "drive",
    "waypoints": [
      {
        "lat": 10.7844142,
        "lon": 106.6397419
      },
      {
        "lat": 10.7757479,
        "lon": 106.7032193
      }
    ],
    "units": "metric"
  },
  "type": "FeatureCollection"
}

const limeOptions = { color: 'red' }



const Map = () => {
  const [draggable, setDraggable] = useState(false)
  const [position, setPosition] = useState(center)
  const [pos, setPos] = useState(reOrderlatlong(responseGeo.features[0].geometry.coordinates[0]))
  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, [])
  return (<>
    <h1>Bản đồ</h1>
    <MapContainer
      style={{height:'80vh'}}
      center={center} 
      zoom={13} 
      scrollWheelZoom={false} >
      
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Polyline pathOptions={limeOptions} positions={pos} />
      <Marker key={0} position={pos[0]}>
        <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>Start</Tooltip>
      </Marker>
      <Marker key={1} position={pos[pos.length-1]}>
        <Tooltip direction="right" offset={[0, 0]} opacity={1} permanent>End</Tooltip>
      </Marker>
      {/*<Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup>
      </Marker>*/}
      
    </MapContainer>
  </>
  )
}


export default Map;

