import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select, Checkbox} from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { PostService } from '../commission/commissionService';

const PostComposition = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({});
  const [compState, setCompState] = useState({
    title: 'Cập nhật'
  });

  useEffect(() => {
    init();
  }, []);

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await PostService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const init = () => {
    Popup.spin.show();
    PostService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          ...itemState,
          id: item.id,
          commission: item.commission,
          isFinishedCommission: item.isFinishedCommission,
          description: item.description,
          descriptionUpdateBill: item.descriptionUpdateBill,
        });
        setCompState({
          ...compState,
          title: props.id ? item.extId : 'Cập nhật',
          name: item.name,
          phone: item.phone,
          price: item.price,
          payment: item.payment,
          partner: item.partner,
          dateCreated: item.dateCreated,
          dateReturn: item.dateReturn
        });
      }
    })
  }

  if(!compState)return <></>

  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col xs={24} sm={12}>
            <label>Khách hàng</label>
            <Input
              readOnly
							value={compState.name}
						/>
          </Col>
          <Col>
            <label>Số điện thoại</label>
            <Input
              value={compState.phone}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Đơn giá</label>
            <InputNumber
              value={compState.price}
              readOnly
            />
            <Msg target="price" msgs={msgs} className="errorMsg" />
          </Col>
          <Col xs={8} sm={8}>
            <label>Thanh toán</label>
            <InputNumber
              value={compState.payment}
              readOnly
            />
          </Col>
          <Col xs={8} sm={8}>
            <label>Hoa hồng</label>
            <InputNumber
              value={itemState.commission}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, commission: values.floatValue })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <label>Ngày thuê</label>
            <DatePicker
              disabled
              value={moment(compState.dateCreated)}
              format={Locale.getFormat().dateTimeFormat}
              style={{ width: "200px" }}
            />
          </Col>
          <Col xs={12} sm={12}>
            <label>Ngày trả</label>
            <DatePicker
              disabled
              value={compState.dateReturn ? moment(compState.dateReturn): ''}
              format={Locale.getFormat().dateTimeFormat}
              style={{ width: "200px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <label>Mô tả</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.description}
              placeholder="Mô tả chi tiết"
              onChange={({ target: { value } }) => setItemState({ ...itemState, description: value })}
            />
          </Col>
          <Col>
            <label>Lý do cập nhật</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.descriptionUpdateBill}
              placeholder="Lý do cập nhật"
              onChange={({ target: { value } }) => setItemState({ ...itemState, descriptionUpdateBill: value })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={8}>
            <label>Hoa hồng</label>
            <Checkbox 
              checked={itemState.isFinishedCommission}
              onChange={(e) => {
              setItemState({...itemState, isFinishedCommission: e.target.checked})}}>Đã thanh toán</Checkbox>
          </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostComposition;