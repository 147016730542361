import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker, Select, Switch } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { RepairPostService } from './motorBikeService';

const PostRepair = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({
    id: props.id,
    name: '',
    description: '',
    payment: null,
    dateCreated: moment(),
    isOil: false
  });
  const [compState, setCompState] = useState({
    title: props.id ? 'Cập nhật' : 'Tạo mới',
    motorBikeList:[]
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    Popup.spin.show();
    RepairPostService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          id: props.id,
          motorId: item.motorId,
          description: item.description,
          payment: item.payment,
          dateCreated: item.dateCreated,
          isOil: item.isOil
        });
        setCompState({
          ...compState,
          motorBikeList: res.motorBikeList ?? [],
          motorId: item.motorId
        });
      }
    })
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await RepairPostService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  const onChangeMotorbike = (value) => {
    setCompState({
      ...compState,
      motorId: value
    })
    setItemState({
      ...compState,
      motorId: value
    })
  };

  if(!compState) return <></>
  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col>
            <label>Chọn xe</label>
            <Select
              value={compState.motorId}
              showSearch
              placeholder="Tìm xe số"
              optionFilterProp="children"
              onChange={onChangeMotorbike}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
            {compState.motorBikeList.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.extId}
              </Select.Option>
            ))}
            </Select>
            <Msg target="motorbike" msgs={msgs} className="errorMsg" />
          </Col>
          <Col>
            <label>Chi phí</label>
            <InputNumber
              value={itemState.payment}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, payment: values.floatValue })}
            />
            <Msg target="payment" msgs={msgs} className="errorMsg" />
          </Col>
          <Col>
            <label>Ngày sửa</label>
            <DatePicker
              value={moment(itemState.dateCreated)}
              format={Locale.getFormat().dateFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, dateCreated: value ? value.format() : null })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Mô tả</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.description}
              placeholder="Mô tả chi tiết"
              onChange={({ target: { value } }) => setItemState({ ...itemState, description: value })}
            />
            <Msg target="content" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Thay dầu</label>
            <Switch defaultChecked={false} checked={itemState.isOil}
              onChange={(checked) => setItemState({...itemState, isOil: checked})}></Switch>
          </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PostRepair;