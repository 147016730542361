import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, DatePicker } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Popup, Msg, InputNumber } from "../../common/component";

import { OperatingCostService } from './operatingCostService';

const OperatingCostPost = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [itemState, setItemState] = useState({
    id: props.id,
    name: '',
    description: '',
    payment: null,
    incurred: null,
    dateCreated: moment()
  });
  const [compState, setCompState] = useState({
    title: props.id ? 'Cập nhật' : 'Tạo mới'
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    Popup.spin.show();
    OperatingCostService.init({ id: props.id }).then(res => {
      const item = res.item ?? {};
      Popup.spin.hide();
      if (res.success) {
        setItemState({
          id: props.id,
          name: item.name,
          description: item.description,
          payment: item.payment,
          incurred: item.incurred,
          dateCreated: item.dateCreated
        })
      }
    })
  }

  const onSubmit = async () => {
    Popup.spin.show();
    var res = await OperatingCostService.submit({
      ...itemState
    });
    Popup.spin.hide();
    if (res.success) {
      Popup.info({ autoClose: true });
      props.onOk();
    }
    setMsgs(res.msgs);
  }

  const onCancel = () => {
    if (!props.onCancel) return;
    props.onCancel();
  }

  return (
    <React.Fragment>
      <div className="form scroll">
        <h2>{compState.title}</h2>
        <Row>
          <Col>
            <label>Tên</label>
            <Input
              value={itemState.name}
              placeholder="Tên chi phí"
              onChange={({ target: { value } }) => setItemState({ ...itemState, name: value })} />
            <Msg target="name" msgs={msgs} className="errorMsg" />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Mô tả</label>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={itemState.description}
              placeholder="Mô tả chi tiết"
              onChange={({ target: { value } }) => setItemState({ ...itemState, description: value })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Chi phí</label>
            <InputNumber
              value={itemState.payment}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, payment: values.floatValue })}
            />
          </Col>
          <Col>
            <label>Chi phí phát sinh</label>
            <InputNumber
              value={itemState.incurred}
              placeholder='VND'
              onChange={(values) => setItemState({ ...itemState, incurred: values.floatValue })}
            />
          </Col>
          <Col>
            <label>Ngày đăng</label>
            <DatePicker
              value={moment(itemState.dateCreated)}
              format={Locale.getFormat().dateFormat}
              style={{ width: "200px" }}
              onChange={(value) => setItemState({ ...itemState, dateCreated: value ? value.format() : null })}
            />
          </Col>
        </Row>
        <div className="footer fixed">
          <Row>
            <Col>
              <Button onClick={onCancel}>Đóng</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onSubmit}>
                Lưu
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OperatingCostPost;