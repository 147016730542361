import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Dropdown, DatePicker, Menu, Input, Select } from "antd";
import moment from "moment";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { ReportManageService } from "../dashboard/reportService";

const today = moment();

const reportInYearOptions = {
  title: {
    text: 'Doanh thu - Chi phí'
  },
  credits:{
    enabled:false
  },
  tooltip: {
    formatter: function () {
      return 'Tháng <b>' + this.x + '</b>: ' + Format.number(this.y);
    }
  },
  yAxis: [{
    title: {
        text: 'VND'
    }
  },
  {
    title: {
        text: 'Số lượng xe'
    },
    opposite: true
  }],
  xAxis: {
    title: {
      text: 'Tháng'
    },
    accessibility: {
        rangeDescription: 'Tháng 01 - 12'
    }
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle'
  },

  plotOptions: {
    series: {
        label: {
            connectorAllowed: false
        },
        pointStart: 1
    }
  },

  series: [],
  responsive: {
    rules: [{
        condition: {
            maxWidth: 500
        },
        chartOptions: {
          legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
          }
        }
    }]
  }
}

const reportMotorbike = {
  chart: {
    type: 'scatter',
  },
  title: {
    text: 'Tỷ lệ doanh thu (- hoa hồng) - bảo trì của mỗi xe'
  },
  legend: {
    enabled :true
  },
  tooltip:{
    enabled:true,
    formatter: function () {
      return 'Xe ' + this.key + ': ' + '<br>' + 'Doanh thu: ' + Format.number(this.y) + '<br> Bảo trì: '  + Format.number(this.x);
    }
  },
  credits:{
    enabled:false
  },
  xAxis: {
    title: {
      text: 'Bảo trì'
    },
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true
  },
  yAxis: {
    title: {
        text: 'Doanh thu'
    }
  },
  series: [{
    name: "",
    color: '#FFA641',
    borderColor: "#FF8605",
    data: []

}]
}

const reportResourceInYearOptions = {
  chart: {
    type: 'column'
  },
  title: {
      text: 'Nguồn khách hàng'
  },
  credits:{
    enabled:false
  },
  xAxis: {
    categories: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12'
    ],
    crosshair: true
  },
  yAxis: [{
    title: {
        useHTML: true,
        text: 'Số lượng khách'
    }
  },
  {
    title: {
        useHTML: true,
        text: 'VND'
    },
    opposite: true
  }],
  plotOptions: {
    column: {
        pointPadding: 0.2,
        borderWidth: 0
    }
  },
  series: [],
}

const Revenue = () => {
  const [compState, setCompState] = useState({
    itemList:[]
  });
  const [chartReportInYearOptions, setChartReportInYearOptions] = useState(reportInYearOptions);
  const [chartReportMotorbikeOptions, setChartReportMotorbikeOptions] = useState(reportMotorbike);
  const [chartReportResourceInYearOptions, setChartReportResourceInYearOptions] = useState(reportResourceInYearOptions);
  const [itemState, setItemState] = useState({
    reportInYear: today.year(),
    reportMotorbikeInYear: today.year(),
    reportResourceInYear: today.year()
  });

  useEffect(() => {
    getList();
  }, [itemState.reportInYear]);

  useEffect(() => {
    getReportMotorbikeList();
  }, [itemState.reportMotorbikeInYear]);

  useEffect(() => {
    getReportResourceList();
  }, [itemState.reportResourceInYear])

  const getList = () => {
    Popup.spin.show();
    ReportManageService.getReportInYear({ year: itemState.reportInYear }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          itemList: res.itemList,
        });

        let newSeries = [];
        let newIncomeSerie = {
          name: 'Doanh thu',
          data:res.itemList.map((item, index) => {
            return res.itemList[index].totalIncomeValue
          })
        };
        let newCommissionSerie = {
          name: 'Hoa Hồng',
          data:res.itemList.map((item, index) => {
            return res.itemList[index].totalCommissionValue
          })
        };
        let newMaintenanceSerie = {
          name: 'Bảo trì',
          data:res.itemList.map((item, index) => {
            return res.itemList[index].totalMaintenanceValue
          })
        };
        let newOperatingSerie = {
          name: 'Chi phí hoạt động',
          data:res.itemList.map((item, index) => {
            return res.itemList[index].totalOperatingCostValue
          })
        };
        let newProfitSerie = {
          name: 'Lợi nhuận',
          data:res.itemList.map((item, index) => {
            return res.itemList[index].totalProfitValue
          })
        };

        let numberMotor = {
          name: 'Số lượng xe',
          type: 'column',
          yAxis: 1,
          data: res.itemList.map((item, index) => {
            return res.itemList[index].totalMotorbike
          })
        }
        newSeries.push(numberMotor);
        newSeries.push(newIncomeSerie);
        newSeries.push(newCommissionSerie);
        newSeries.push(newMaintenanceSerie);
        newSeries.push(newOperatingSerie);
        newSeries.push(newProfitSerie);

        setChartReportInYearOptions({...chartReportInYearOptions, series: newSeries});
      }
    })
  }

  const onChangeYearReportInYear = (value) => {
    if (value) {
      setItemState({
        ...itemState,
        reportInYear: value.year(),
      });
    }
  }

  const onChangeYearReportMotorbikeInYear = (value) => {
    setItemState({
      ...itemState,
      reportMotorbikeInYear: value?.year()
    });
  }

  const getReportMotorbikeList = () => {
    Popup.spin.show();
    ReportManageService.getReportMotorbike({ year: itemState.reportMotorbikeInYear }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        let newSeries = [];
        res.itemKindList.forEach(kind => {
          let serie = {
            data: kind.itemList.map((item, index) => {
              return {
                name: item.extId,
                x: item.totalMaintenanceValue,
                y: item.totalIncomeValue,
              }
            })
          }
          serie.name = kind.name;
          newSeries.push(serie);
        });
        setChartReportMotorbikeOptions({...chartReportMotorbikeOptions, series: newSeries});
      }
    })
  }

  const onChangeYearReportResourceInYear = (value) => {
    if (value) {
      setItemState({
        ...itemState,
        reportResourceInYear: value.year(),
      });
    }
  }

  const getReportResourceList = () => {
    Popup.spin.show();
    ReportManageService.getReportResourceInYear({year: itemState.reportResourceInYear}).then(res => {
      Popup.spin.hide()
      if(res.success) {
        let newSeries = [];
        res.itemList.forEach(item => {
          let serie = {
            name:item.name,
            data:item.numbers.map(num => {
              return num; 
            })
          }
          newSeries.push(serie);
          console.log('newSeries', newSeries);
        });

        res.itemList.forEach(item => {
          let serie = {
            name: 'Doanh thu ' +item.name,
            type: 'spline',
            yAxis: 1,
            tooltip: {
              valueSuffix: 'VND'
            },
            data:item.totalIncomes.map(value => {
              return value; 
            })
          }
          newSeries.push(serie);
        });
        setChartReportResourceInYearOptions({...chartReportResourceInYearOptions, series: newSeries});
      }
    })
  }

  if(!compState)return <></>

  return (
    <React.Fragment>
      <h1>Báo cáo Xe Rất Xịn</h1>
      <div className="main">
        <Row>
          <Col md={12} xs={24} sm={24}>
            <div className="box">
              <div>
                <span>Năm</span>
                <DatePicker picker="year" bordered={false} defaultValue={today} onChange={onChangeYearReportInYear} allowClear={false}/>
              </div>
              <HighchartsReact
              highcharts={Highcharts}
              options={chartReportInYearOptions}
              />
            </div>
          </Col>
          <Col md={12} xs={24} sm={24}>
            <div className="box">
              <div>
                <span>Năm</span>
                <DatePicker placeholder="Tất cả" picker="year" bordered={false} defaultValue={today} onChange={onChangeYearReportMotorbikeInYear} allowClear={true} />
              </div>
              <HighchartsReact
              highcharts={Highcharts}
              options={chartReportMotorbikeOptions}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={24} sm={24}>
            <div className="box">
              <div>
                <span>Năm</span>
                <DatePicker picker="year" bordered={false} defaultValue={today} onChange={onChangeYearReportResourceInYear} allowClear={false}/>
              </div>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartReportResourceInYearOptions}
                />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Revenue;