import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Dropdown, DatePicker, Menu, Input, Select, Button } from "antd";
import moment from "moment";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { ReportManageService } from "../dashboard/reportService";

const { RangePicker } = DatePicker;
const today = moment();

const reportCompareMonthOptions = {
  chart: {
    type: 'column'
  },
  title: {
      text: 'So sánh'
  },
  credits:{
    enabled:false
  },
  
  yAxis: [{
    title: {
        useHTML: true,
        text: 'VND'
    }
  }],
  plotOptions: {
    column: {
        pointPadding: 0.2,
        borderWidth: 0
    }
  },
  series: [],
}

const CompareGrossIncome = () => {
  const [compState, setCompState] = useState({
    itemList:[],
    defaultToday:today
  });

  const [itemState, setItemState] = useState({})
  const [chartReportCompareMonthOptions, setChartReportCompareMonthOptions] = useState(reportCompareMonthOptions);

  useEffect(() => {
    getList();
  }, [itemState]);

  const onGetList = () => {
    getList();
  }

  const getList = () => {
    Popup.spin.show();
    ReportManageService.getCompareGrossIncomeInMonth({ ...itemState }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        let newSeries = [];
        let month1 = {
          name: res.itemList[0].month,
          data: [res.itemList[0].value]
        };
        let month2 = {
          name: res.itemList[1].month,
          data: [res.itemList[1].value]
        };
        newSeries.push(month1);
        newSeries.push(month2);
        setChartReportCompareMonthOptions({...chartReportCompareMonthOptions, series: newSeries});

        setCompState({
          itemList: res.itemList,
        });
      }
    })
  }

  const onChangeRangepickerA = (dates) => {
    if(dates.length > 1){
      setItemState({
        ...itemState,
        AfromDate: dates[0].format("YYYY-MM-DD"),
        AtoDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  }

  const onChangeRangepickerB = (dates) => {
    if(dates.length > 1){
      setItemState({
        ...itemState,
        BfromDate: dates[0].format("YYYY-MM-DD"),
        BtoDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  }

  if(!compState)return <></>

  return (
    <React.Fragment>
      <h1>So sánh doanh thu theo ngày</h1>
      <Row align="middle">
        <Col>
          <span>Chọn ngày </span>
          <RangePicker
            defaultValue={[compState.defaultToday, compState.defaultToday]}
            allowEmpty={[false,false]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRangepickerA}
            allowClear={false}
            />
        </Col>
        <Col>
          <span>So sánh với </span>
          <RangePicker
            defaultValue={[compState.defaultToday, compState.defaultToday]}
            allowEmpty={[false,false]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRangepickerB}
            allowClear={false}
            />
        </Col>
        <Col>
          <Button type="primary" onClick={onGetList}>
            <span>Xem</span>
          </Button>
        </Col>
      </Row>
      <div className="box">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartReportCompareMonthOptions}/>
      </div>
    </React.Fragment>
  )
}

export default CompareGrossIncome;