import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Affix, Dropdown, Menu, Row, Col } from "antd";

import * as Const from "../../common/const";
import { Format } from "../../common/format";

import { CurrentUserService } from "../user/currentUserService";
import { LoginService } from "../user/userService";

import SiderBase, { siderService } from "../../common/siderBase";
import Sider from "./sider";

const Header = () => {
	let navigate = useNavigate();
	const [compState, setCompState] = useState({
		userCurrent: CurrentUserService.getUser()
	});
	const [menu, setMenu] = useState([]);

	useEffect(() => {
		const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCompState({ userCurrent: user });
		});

		getMenu();

		return () => {
			userCurrentSubscription.unsubscribe();
		}

	}, []);

	const logoutHandle = () => {
		LoginService.logoutSubmit().then(() => window.location = "/login");
	};

	const getMenu = () => {
		setMenu([
			{
				key: 'logout',
				label: <span onClick={logoutHandle}>
					<i className="icon fa fa-power-off"></i>
					<span>Đăng xuất</span>
				</span>
			},
			//{
			//	key: 'dashboard',
			//	label: <span onClick={logoutHandle}>
			//		<i className="icon fa fa-power-off"></i>
			//		<span>Tổng quan</span>
			//	</span>
			//}
		])
	}

	return (
		<header className="header">
			<Affix>
				<div className="mainbar">
					<span className="link sider-button" onClick={() => siderService.visible()}>
						<i className="far fa-bars"></i>
					</span>
					{compState.userCurrent ? (
						<Dropdown
							trigger={["click"]}
							overlay={
								<Menu items={menu}>
									{/*<div className="header-user-info">
										<div className="avatar">
											<i className="fal fa-user-circle"></i>
										</div>
										<div className="info">
											<div className="username">{compState.userCurrent.userName}</div>
											<div className="fullname">{compState.userCurrent.fullName}</div>
										</div>
									</div>*/}
									{/*<Menu.Divider />*/}
									{/*<Menu.Item key="logout">
										<span onClick={this.logoutHandle}>
											<i className="icon fa fa-power-off"></i>
											<span>Đăng xuất</span>
										</span>
									</Menu.Item>*/}
								</Menu>
							}
						>
							<span className="mainbar-button user" onClick={(e) => e.preventDefault()}>
								<i className="fas fa-user-circle"></i>
							</span>
						</Dropdown>
					) : (
						<Link to="/login" className="mainbar-button">
							<i className="far fa-user-circle"></i>
							<span>Login</span>
						</Link>
					)}
				</div>
			</Affix>
			<SiderBase>
				<Sider />
			</SiderBase>
		</header>
	)
}

export default Header;