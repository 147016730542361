import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Input, Button, Pagination, Dropdown, Select, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg, InputComp } from "../../common/component";

import { BillManageService } from './billService';
import { CurrentUserService } from "../user/currentUserService";

import PostBill from "./postBill";

const { RangePicker } = DatePicker;
const today = moment();

const ManageBill = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    itemList: [],
    defaultToday:today
  });

  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    orderBy: 'DateDec',
    isPaid: false,
    fromDate: today.format("YYYY-MM-DD"),
		toDate: today.format("YYYY-MM-DD"),
    partnerId: null
  });

  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });

  const partnerListRef = useRef([]);

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			setCurrentUserState({currentUser: user});
		});

    init();

		return () => {
			userCurrentSubscription.unsubscribe();
		}
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  const init = () => {
    Popup.spin.show();
    BillManageService.init().then(res => {
      Popup.spin.hide()
      if(res.success){
        partnerListRef.current = res.partnerList
      }
    })
  }

  const getList = () => {
    Popup.spin.show();

    let filterParams = {
      ...filterState,
    };

    if(filterState.isPaid === false){
      filterParams.fromDate = null;
      filterParams.toDate = null;
    }

    BillManageService.getList({ ...filterParams }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
          totalIncomeValue: res.totalIncomeValue
        });
      }
    })
  }

  const onChangeStatus = (value) => {
    if(value === false){
      setFilterState({
        ...filterState,
        isPaid: false,
        fromDate: today.format("YYYY-MM-DD"),
    		toDate: today.format("YYYY-MM-DD"),
      })
    } else {
      setFilterState({
        ...filterState,
        isPaid: true,
      })
    }
  }

  const onChangeSearchBill = (event) => {
    setFilterState({
      ...filterState,
      searchKey: event.target.value 
    })
  }

  const onChangePartner = (value) => {
    setFilterState({
      ...filterState,
      partnerId: value
    });
  }

  const onChangeRangepicker = (dates) => {
    if(dates.length > 1){
      setFilterState({
        ...filterState,
        fromDate: dates[0].format("YYYY-MM-DD"),
        toDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  }

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      isItemDrawer: false,
    });
  };

  const onAddItem = () => {
    setItemState({
      isItemDrawer: true,
    });
  }

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Hóa đơn</h1>
      <Row align="middle">
        <Col>
          <Select
            defaultValue={filterState.isPaid}
            value={filterState.isPaid}
            onChange={onChangeStatus}
          >
            <Select.Option value={false}>Chưa thanh toán</Select.Option>
            <Select.Option value={true}>Đã thanh toán</Select.Option>
          </Select>
        </Col>
        {partnerListRef.current &&
          <Col>
            <Select
              value={compState.partnerId}
              showSearch
              allowClear
              style={{ width: "160px" }}
              placeholder="Tìm đối tác"
              optionFilterProp="children"
              onChange={onChangePartner}
              filterOption={(input, option) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
            {partnerListRef.current.map(item => (
              <Select.Option key={item.partnerId} value={item.partnerId}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          </Col>
        }
        <Col>
          <InputComp placeholder="Tìm số xe hoặc SĐT" onChange={onChangeSearchBill} value={filterState.searchKey}/>
        </Col>
        {filterState.isPaid && <Col>
          <RangePicker
            defaultValue={[compState.defaultToday, compState.defaultToday]}
            allowEmpty={[false,false]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRangepicker}
            allowClear={false}
            />
          </Col>
        }
      </Row>
      <Row align="middle" justify="end">
        <Col>Số lượng hóa đơn: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col style={{ marginLeft: 'auto' }}>
          {currentUserState.currentUser && currentUserState.currentUser.canEdit &&
            <Button type="primary" onClick={onAddItem}>
              <i className="fa fa-plus" />
              <span>Thuê xe</span>
            </Button>
          }
        </Col>
        <Col>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        {filterState.isPaid ? <div className="title-box">
          <h3>Doanh thu: <strong className="income">{Format.number(compState.totalIncomeValue)}</strong></h3>
        </div> : ''}
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Số xe</li>
            <li>Ngày thuê</li>
            <li>{filterState.isPaid ? "Ngày trả" : "Dự kiến thuê"}</li>
            <li>Giá thuê</li>
            <li>Hoa hồng</li>
            <li>Ghi chú</li>
            {filterState.isPaid &&
              <li>Thành tiền</li>}
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <div>
                  <strong>
                    <a onClick={() => onEditItem(item)}>{item.name}</a>
                  </strong>
                </div>
                <div><a onClick={() => onEditItem(item)}>{item.extId}</a></div>
                {item.phone && item.phone !== '0' ? 
                  <em><strong>{item.phone}</strong></em> : ''
                }
              </li>
              <li>
                <div>{Format.date(new Date(item.dateCreated))}</div>
                <div>{Format.time(new Date(item.dateCreated))}</div>
              </li>
              <li>
              {filterState.isPaid ? 
                <>
                  <div>{Format.date(new Date(item.dateReturn))}</div>
                  <div>{Format.time(new Date(item.dateReturn))}</div>
                </>
                : item.expected + ' ngày'}
              </li>
              <li>
                <strong>{Format.number(item.price)}</strong>
              </li>
              <li>
                <div>
                  <strong>{Format.number(item.commission)}</strong>
                </div>
                <em>{item.partner}</em>
              </li>
              <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}>
              </li>
              {filterState.isPaid && <>
                <li>
                  <strong>{Format.number(item.payment)}</strong>
                </li>
              </>}
              {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li style={{float:'right'}}>
                <Button type="primary" onClick={() => onEditItem(item)}>
                  Cập nhật
                </Button>
              </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value }, () => getList())}
            />
          }
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostBill id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManageBill;