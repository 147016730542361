import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Input, Button } from "antd";

import { LoginService } from "./userService";
import { CurrentUserService } from "./currentUserService";

import { Popup, Msg } from "../../common/component";

const Login = (props) => {
  let navigate = useNavigate();

  const [compState, setCompState] = useState({
    userName: "",
		password: "",
		msgs: [],
  });

  const onSubmit = async () => {
		Popup.spin.show();
		const res = await LoginService.loginSubmit({
			userName: compState.userName,
			password: compState.password,
		});
		Popup.spin.hide();
		if (res.success && res.data != null) {
			LoginService.tokenSet(res.data);
			//CurrentUserService.checkUser().then(() => navigate("/motobike"));
      CurrentUserService.checkUser().then(() => navigate("/"));
		}
		setCompState({ msgs: res.msgs });
	};

  return (
    <div className="login-page">
      <div className="login-form form">
        <Row>
          <Col>
            <h1>Đăng nhập</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              placeholder="Tên truy cập"
              value={compState.userName}
              prefix={<i className="far fa-user"></i>}
              onChange={(event) => {
                setCompState({...compState, userName: event.target.value });
              }}
              onPressEnter={onSubmit}
              autoFocus={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input.Password
              placeholder="Mật khẩu"
              value={compState.password}
              prefix={<i className="far fa-key"></i>}
              onChange={(event) => {
                setCompState({...compState, password: event.target.value });
              }}
              onPressEnter={onSubmit}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Msg target="login" msgs={compState.msgs} className="errorMsg" />
            <Button type="primary" onClick={onSubmit}>
              Đồng ý
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="link">
              <Link to="/forgetPassword">Quên mật khẩu</Link>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Login;