
import * as Service from "../../common/serviceHelper";

export const PartnerManageService = {
  init: async (param) => await Service.get("api/partner/manage/getList", param)
}

export const PostService = {
  init: async (param) => await Service.get("api/partner/post/init", param),
  submit: async (param) => await Service.post("api/partner/post/submit", param),
}