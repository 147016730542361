import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Select } from "antd";

import { Popup, Msg } from "../../common/component";

import { RegisterService } from "./userService";

const Register = () => {
  const [msgs, setMsgs] = useState();
  const [itemState, setItemState] = useState({
    userTypeId: null,
		userName: null,
		email: null,
		firstName: null,
    lastName: null,
		phone: null,
		password: null,
		confirmPassword: null,
  });
  const [compState, setCompState] = useState({
    userTypeList: [],
  });

  useEffect(() => {
    init()
  }, []); 

	const init = () => {
		Popup.spin.show();
		RegisterService.init().then((res) => {
			if (res) {
				setCompState({
					userTypeList: res.itemList,
				});
			}
			Popup.spin.hide();
		});
	};

	const onSubmit = async () => {
		Popup.spin.show();
		const res = await RegisterService.submit({
			...itemState
		});
		Popup.spin.hide();
		if (res.success) {
			setItemState({});
      setMsgs();
			init();
			Popup.info({ autoClose: true });
		} else setMsgs(res.msgs);
	};

	return (
		<div className="register-page">
			<h1 className="page-title">Đăng ký</h1>
			<input type="text" className="input-hidden" />
			<input type="password" className="input-hidden" />
			<div className="form">
				<div className="box">
					<div className="title">Thông tin cơ bản</div>
					<Row>
						<Col xs={24} sm={12}>
							<label>Tên đăng nhập</label>
							<Input
								value={itemState.userName}
								onChange={(event) => {
									setItemState({ ...itemState, userName: event.target.value });
								}}
							/>
							<Msg target="userName" msgs={msgs} className="errorMsg" />
						</Col>
            <Col>
              <label>Chức vụ</label>
                <Select
									style={{ width: "100%" }}
									placeholder="Chọn chức vụ"
									onChange={(value) => setItemState({ ...itemState, userTypeId: value })}
									value={itemState.userTypeId}
								>
									{compState.userTypeList.map(item => (
                    <Select.Option key={item.id} value={item.id}>
											{item.name}
										</Select.Option>
                  ))}
								</Select>
            </Col>
					</Row>
					<Row>
						<Col xs={24} sm={12}>
							<label>Họ</label>
							<Input
								value={itemState.firstName}
								onChange={(event) => {
									setItemState({ ...itemState, firstName: event.target.value });
								}}
							/>
							<Msg target="firstName" msgs={msgs} className="errorMsg" />
						</Col>
						<Col xs={24} sm={12}>
							<label>Tên</label>
							<Input
								value={itemState.lastName}
								onChange={(event) => {
									setItemState({ ...itemState, lastName: event.target.value });
								}}
							/>
							<Msg target="lastName" msgs={msgs} className="errorMsg" />
						</Col>
					</Row>
          <Row>
            <Col xs={24} sm={12}>
							<label>Email</label>
							<Input
								value={itemState.email}
								onChange={(event) => {
									setItemState({ ...itemState, email: event.target.value });
								}}
							/>
							<Msg target="email" msgs={msgs} className="errorMsg" />
						</Col>
            <Col xs={24} sm={12}>
							<label>Điện thoại</label>
							<Input
								value={itemState.phone}
								onChange={(event) => {
									setItemState({ ...itemState, phone: event.target.value });
								}}
							/>
							<Msg target="phone" msgs={msgs} className="errorMsg" />
						</Col>
          </Row>
					<Row>
						<Col>
							<label>Mật khẩu</label>
							<Input.Password
								value={itemState.password}
								onChange={(event) => {
									setItemState({...itemState, password: event.target.value });
								}}
							/>
							<Msg target="password" msgs={msgs} className="errorMsg" />
						</Col>
						<Col>
							<label>Nhập lại mật khẩu</label>
							<Input.Password
								value={itemState.confirmPassword}
								onChange={(event) => {
									setItemState({ ...itemState, confirmPassword: event.target.value });
								}}
							/>
							<Msg target="confirmPassword" msgs={msgs} className="errorMsg" />
						</Col>
					</Row>
					<Row>
						<Col>
							<em>
								- Mật khẩu ít nhất 8 ký tự
								<br />
								- Phải có CHỮ HOA, chữ thường, con số
								<br />
								- Và các ký tự đặc biệt: ! @, #, $, %, ^, &amp;, *<br />
								<br />
							</em>
						</Col>
					</Row>
          <div className="divider" />
						<Row>
							<Col>
								<Button type="primary" onClick={onSubmit}>
									Đăng ký
								</Button>
							</Col>
						</Row>
				</div>
			</div>
		</div>
	);
}

export default Register;