
import * as Service from "../../common/serviceHelper";

export const MotorBikeManageService = {
  init: async (param) => await Service.get("api/motor/manage/init", param),
  detail: async (param) => await Service.get("api/motor/manage/detail", param),
  detailInMonth: async (param) => await Service.get("api/motor/manage/detailInMonth", param),
  getGpsList: async (param) => await Service.get("api/motor/manage/viettelMotors", param),
  getDistance: async (param) => await Service.get("api/motor/manage/getDetailTranport", param),
  syncOdometer: async (param) => await Service.get("api/motor/manage/syncOdometer", param),
  syncOdometerAll: async (param) => await Service.get("api/motor/manage/syncOdometerAll", param),
}

export const PostMotorBikeService = {
  init: async (param) => await Service.get("api/motor/post/init", param),
  submit: async (param) => await Service.post("api/motor/post/submit", param),
}

export const MotorBikeMaintenanceManageService = {
  init: async (param) => await Service.get("api/maintenance/manage/init", param),
  getRepairList: async (param) => await Service.get("api/maintenance/manage/getrepairlist", param)
}

export const RepairPostService = {
  init: async param => await Service.get("api/maintenance/post/init", param),
  submit: async (param) => await Service.post("api/maintenance/post/submit", param),
  delete: async param => await Service.post("api/maintenance/post/delete", param),
}