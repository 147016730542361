import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Switch, Button, Pagination, Dropdown, Select, Menu, DatePicker, Drawer } from "antd";
import moment from "moment";

import { Locale } from "../../common/locale";
import { Format } from "../../common/format";
import { Popup, Msg } from "../../common/component";

import { MotorBikeMaintenanceManageService, RepairPostService } from './motorBikeService';
import { CurrentUserService } from "../user/currentUserService";
import PostRepair from "./postRepair";

const { RangePicker } = DatePicker;

const ManageRepair = () => {
  const [currentUserState, setCurrentUserState] = useState({
    currentUser: CurrentUserService.getUser()
  });
  const [compState, setCompState] = useState({
    defaultFormDate: moment().startOf('month'),
    defaultToDate: moment(),
    itemList: [],
    motorBikeList: []
  });
  const [filterState, setFilterState] = useState({
    pageIndex: 1,
    orderBy: 'DateDec',
    fromDate: moment().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().format("YYYY-MM-DD"),
    motorId: null,
    isOil: null
  });
  const [itemState, setItemState] = useState({
    isItemDrawer: false
  });
  
  const motorListRef = useRef([]);

  useEffect(() => {
    const userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
      setCurrentUserState({ currentUser: user });
    });

    MotorBikeMaintenanceManageService.init().then(res => {
      if(res.success){
        motorListRef.current = res.motorBikeList ?? [];
      }
    });

    return () => {
      userCurrentSubscription.unsubscribe();
    }
  }, []);

  useEffect(() => {
    getList();
  }, [filterState]);

  const getList = () => {
    Popup.spin.show();
    MotorBikeMaintenanceManageService.getRepairList({ ...filterState }).then(res => {
      Popup.spin.hide()
      if (res.success) {
        setCompState({
          ...compState,
          itemList: res.itemList,
          totalPayment: res.totalPayment,
          pageIndex: res.pageIndex,
          pageSize: res.pageSize,
          totalPages: res.totalPages,
          totalItems: res.totalItems,
        });
      }
    })
  }

  const onChangeRangepicker = (dates) => {
    if (dates.length > 1) {
      setFilterState({
        ...filterState,
        fromDate: dates[0].format("YYYY-MM-DD"),
        toDate: dates[1].format("YYYY-MM-DD"),
      });
    }
  }

  const onChangeOil = (active) => {
    setFilterState({
      ...filterState,
      isOil: active ? active : null
    })
  }

  const onChangeMotorbike = (value) => {
    setFilterState({
      ...filterState,
      extId: value
    });
  }

  const onFunctionUpdate = (e) => {
    e.preventDefault();

    Popup.info({
      title: "Tính năng đang tạm khoá",
    });
  };

  const onAddItem = () => {
    setItemState({
      isItemDrawer: true,
    });
  }

  const onItemCancel = () => {
    setItemState({
      isItemDrawer: false,
    });
  }

  const onItemOk = () => {
    getList();
    setItemState({
      isItemDrawer: false,
    });
  };

  const deleteItem = (item) => {
    Popup.spin.show();
    RepairPostService.delete(item.id).then((res) => {
      Popup.spin.hide();
      getList();
    });
  };

  const onRemoveItem = (item) => {
    Popup.confirm({
      title: Locale.getText("common.delete_confirm"),
      content: item.name,
      onOk: () => deleteItem(item),
    });
  }

  const onEditItem = (item) => {
    setItemState({
      itemId: item.id,
      isItemDrawer: true,
    });
  };

  if (!compState) return <></>
  return (
    <React.Fragment>
      <h1>Bảo trì xe</h1>
      <Row align="middle">
      { !filterState.isOil && <Col>
          <RangePicker
            defaultValue={[compState.defaultFormDate, compState.defaultToDate]}
            allowEmpty={[false, false]}
            format={Locale.getFormat().dateFormat}
            onChange={onChangeRangepicker}
            allowClear={false}
          />
        </Col>
      }
        
        <Col>
          <Select
            value={compState.motorId}
            showSearch
            style={{ width: "160px" }}
            placeholder="Tìm xe số"
            optionFilterProp="children"
            onChange={onChangeMotorbike}
            filterOption={(input, option) =>
              (option?.children).toLowerCase().includes(input.toLowerCase())
            }
          >
            {motorListRef.current.map(item => (
              <Select.Option key={item.id} value={item.extId}>
                {item.extId}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Switch checkedChildren="Thay dầu" unCheckedChildren="Tất cả" onChange={onChangeOil}></Switch>
        </Col>
      </Row>
      <Row align="middle" justify="end">
        <Col>Số lần bảo trì: <span style={{ fontSize: 20 }}>{compState.totalItems}</span></Col>
        <Col style={{ marginLeft: 'auto' }}>
          {currentUserState.currentUser && currentUserState.currentUser.canEdit &&
            <Button type="primary" onClick={onAddItem}>
              <i className="fa fa-plus" />
              <span>Thêm</span>
            </Button>
          }
        </Col>
        <Col>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <div className="box">
        <div className="title-box">
          <h3>Tổng chi phí: <strong className="income">{Format.number(compState.totalPayment)}</strong></h3>
        </div>
        <div className="table has-left-col has-menu">
          <ol>
            <li>#</li>
            <li>Số xe</li>
            <li>Ngày sửa</li>
            <li>Mô tả</li>
            <li>Thay dầu</li>
            <li>Số tiền</li>
            {currentUserState.currentUser && currentUserState.currentUser.canEdit && <li></li>}
          </ol>
          {compState.itemList.map((item, index) => (
            <ul key={item.id}>
              <li className="id-col collapsing">{index + 1}</li>
              <li>
                <a onClick={() => onEditItem(item)}>{item.extId}</a>
              </li>
              <li>{Format.date(new Date(item.dateCreated))}</li>
              <li style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{__html: item.description}}>
              </li>
              <li>
                <Switch defaultChecked={false} checked={item.isOil}></Switch>
              </li>
              <li>
                {Format.number(item.payment)}
              </li>
              {currentUserState.currentUser && currentUserState.currentUser.canEdit &&
                <li className="menu-col">
                  <Dropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    overlay={
                      <Menu items={[
                        {
                          key: 'update',
                          label: <a onClick={() => onEditItem(item)}>
                            <span>Cập nhật</span>
                          </a>,
                          icon: <i className="icon fal fa-pen"></i>
                        },
                        {
                          key: 'delete',
                          label: <a onClick={() => onRemoveItem(item)}>
                            <span>Xóa</span>
                          </a>,
                          icon: <i className="icon fal fa-trash-alt"></i>
                        },
                      ]}>
                      </Menu>
                    }
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <i className="far fa-ellipsis-v" />
                    </a>
                  </Dropdown>
                </li>}
            </ul>
          ))}
        </div>
      </div>
      <Row gutter={[0, 10]}>
        <Col style={{ marginLeft: "auto" }}>
          {compState.itemList.length > 0 &&
            <Pagination
              current={compState.pageIndex}
              total={compState.totalItems}
              pageSize={compState.pageSize}
              showSizeChanger={false}
              onChange={(value) => setFilterState({ ...filterState, pageIndex: value })}
            />
          }
        </Col>
      </Row>
      <Drawer
        placement="right"
        onClose={onItemCancel}
        visible={itemState.isItemDrawer}
        maskClosable={false}
        destroyOnClose={true}
        width={768}
        className="drawer"
      >
        <PostRepair id={itemState.itemId} onCancel={onItemCancel} onOk={onItemOk} />
      </Drawer>
    </React.Fragment>
  )
}

export default ManageRepair;