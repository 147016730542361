
import * as Service from "../../common/serviceHelper";

export const BillManageService = {
  init: async (param) => await Service.get("api/motor/bill/init", param),
  getList: async (param) => await Service.get("api/motor/bill/getbilllist", param)
}

export const PostService = {
  init: async (param) => await Service.get("api/bill/post/init", param),
  submit: async (param) => await Service.post("api/bill/post/submit", param),
}